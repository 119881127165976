@import '../../../../styles/helpers';

.reviewsList {
  position: relative;
  z-index: 1;
  margin: 64px auto 0;

  @include media(tablet) {
    margin-top: 120px;

    &_single {
      margin-top: 96px;
    }
  }

  @include media(desktopAll) {
    margin-top: 120px;

    &_single {
      margin-top: 96px;
    }
  }

  :global {
    .splide__slide {

      &:not(.is-active) {
        opacity: 0.3;
        pointer-events: none;
      }

    }
  }

  >div {
    height: 360px;

    @at-root .reviews_single#{&} {
      height: 526px;
    }

    @include media(desktopHuge) {
      height: 832px;
    }

    @include media(portrait) {
      height: 222px;

      @at-root .reviews_single#{&} {
        height: 360px;
      }
    }

    @include media(mobile) {
      width: 100%;
      height: auto;
      aspect-ratio: 2 / 1;

      @at-root .reviews_single#{&} {
        height: auto;
      }
    }
  }

  * {
    transition: none;
  }

  &.default-view {
    >div {
      aspect-ratio: unset;
      height: auto;
    }
  }
}

$review-width: 728px;
$review-width-desktopHuge: 1682px;
$review-width-tablet: 450px;
$review-width-mobile: calc(100vw - 64px);

.review {
  width: $review-width;
  height: 360px;

  @include media(desktopHuge) {
    height: 832px;
    width: $review-width-desktopHuge;
  }

  @include media(portrait) {
    width: $review-width-tablet;
    height: 222px;
  }

  @include media(mobile) {
    width: $review-width-mobile;
    height: auto;
    aspect-ratio: 2 / 1;
  }

  &_single {
    width: 100% !important;
    height: 526px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  background-color: transparent;
  width: 136px;
  border-radius: 0;
  display: none;
  cursor: default;

  @include media(desktopAll) {
    display: block;
    width: 136px;
    height: 94px;
  }

  @include media(desktopHuge) {
    width: 314px;
    height: 217px;
  }

  &_prev {
    left: calc(50% - $review-width / 2);
    transform: translate(calc(-100% - 30px), -50%);

    @include media(desktopHuge) {
      left: calc(50% - $review-width-desktopHuge / 2);
    }

    img {
      transform: rotate(180deg)
    }
  }

  &_next {
    right: calc(50% - $review-width / 2);
    transform: translate(calc(100% + 30px), -50%);

    @include media(desktopHuge) {
      right: calc(50% - $review-width-desktopHuge / 2);
    }
  }
}

.paginationSlider {
  position: relative;
    max-height: 96px;

    &::before,
    &::after {
      content: '';
      height: 100%;
      width: 104px;
      position: absolute;
      top: 0;
      z-index: 1;
      pointer-events: none;

      @include media(landscape) {
        width: 370px;
      }

      @include media(desktopAll) {
        width: 370px;
      }
    }

    &::before {
      left: 0;
      @include setProperty(background, linear-gradient(90deg, #141414 0%, rgba(20, 20, 20, 0.00) 100%), linear-gradient(90deg, #F6F6F6 0%, rgba(246, 246, 246, 0.00) 100%));
    }

    &::after {
      right: 0;
      @include setProperty(background, linear-gradient(270deg, #141414 0%, rgba(20, 20, 20, 0.00) 100%), linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0.00) 100%));
    }
  
    .paginationButton {
      border-radius: 8px;
      text-align: center;
      height: 88px;
      padding: 1px;
      position: relative;
      width: 275px;
      margin: 0 8px;

      @include media(notMobile) {
        width: 300px;
      }

      .buttonContentWrapper {
        @include setProperty(background, var(--secondary-700), var(--secondary-50));
        padding: 20px 24px;
        border-radius: 8px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;

        &.onlyIndustries {
          padding: 0;
        }
      }
    
      .buttonTitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.24px;
        opacity: 0.5;
        @include setProperty(color, var(--secondary-50), var(--secondary-400));
      }
    
      .buttonContent {
        font-family: "KharkivTone" sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @include setProperty(color, var(--secondary-100), var(--secondary-500));
        white-space: break-spaces;
      }
  
      .activeEllipse {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        border-radius: 8px;
      }
  
      &.active {
        @include setProperty(background,linear-gradient(85deg, rgba(42,54,52,1) 16%, rgba(42,54,52,0.4) 93%), linear-gradient(85deg, #A3A3A34D, #A3A3A300));


        .activeEllipse {
          visibility: visible;
        }
  
        margin-top: 0;
  
        .buttonContent {
          color: var(--primary-500);
        }
      }
    }
  
    :global {
      .splide__slide {
        &:not(.is-active) {
          opacity: 1;
          pointer-events: all;
        }

        &.is-active {
          border: none !important;
        }
      }
    }
  }


  .review {
    position: relative;
  
    .reviewContent {
      display: none;
    }
  
    &.default-view {
      margin: auto;
      margin-top: 56px;
      width: calc(100% - 48px);
      aspect-ratio: unset;
      height: auto;
  
      @include media(portrait) {
        width: calc(100% - 128px);
      }
  
      @include media(landscape) {
        display: flex;
        width: calc(100% - 144px);
      }
  
      @include media(desktopAll) {
        display: flex;
        width: 1280px;
      }
  
      @include media(desktopSmall) {
        width: 1040px;
      } 
  
      @include media(desktop) {
        width: 1040px;
      }
  
      img {
        position: static !important;
      }
  
      .preview {
        aspect-ratio: 3 / 2;
      }
  
      .reviewContent {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        padding: 32px;
        @include setProperty(background-color, var(--secondary-800), var(--light-line-low-accent));
        overflow: hidden;
  
        @include media(landscape) {
          max-width: 60%;
        }
  
        @include media(desktopAll) {
          padding: 40px;
          justify-content: space-between;
          max-width: 50%;
        }
  
        .reviewText {
          font-size: 16px;
          line-height: 32px;
          font-weight: 300;
          letter-spacing: 0.183px;
          @include setProperty(color, var(--secondary-50), var(--secondary-500));
  
          span {
            opacity: 0.6;
          }
        }
  
        .reviewIcon {
          width: 40px;
          height: 40px;
        }
  
        .reviewFooter {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-self: stretch;
    
          @include media(notMobile) {
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
  
      button {
        width: 100%;
  
        .author {
          display: none;
        }
      }
  
      .video {
        width: 100%;
        aspect-ratio: 3 / 2;
  
        @include media(landscape) {
          height: auto;
        }
  
        @include media(desktopAll) {
          height: auto;
        }
      }
  
      .author {
        position: relative;
        background: none;
        gap: 16px;
        padding: 0;
  
        &__avatar {
          width: 48px;
          height: 48px;
        }
  
        &__name {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 4px;
          letter-spacing: 0.183px;
        }
  
        &__position {
          font-size: 14px;
          line-height: 28px;
          font-weight: 300;
        }
  
        &__clutch {
          display: flex;
          align-items: center;
          align-self: flex-end;
          gap: 8px;
          border-radius: 200px;
          padding: 12px 16px;
          @include setProperty(background-color, var(--secondary-700), var(--secondary-50));
          width: fit-content;
          height: fit-content;
  
          .logo {
            height: 12px;
          }
  
          @include media(notMobile) {
            width: 194px;
          }
        }
      }
  
      .blur {
        width: 429px;
        height: 40px;
        position: absolute;
        right: -159px;
        bottom: 43px;
        border-radius: 200px;
        opacity: 0.4;
        background-color: var(--primary-400);
        filter: blur(85px);
  
        @include media(portrait) {
          right: 200px;
        }
  
        @include media(landscape) {
          right: 100px;
        }
  
        @include media(desktopAll) {
          bottom: -33px;
          right: 20%;
        }
       }
    }
  }
  
  .video {
    height: 100%;
  
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  
  .preview {
    cursor: pointer;
  }
  
  .author {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    width: 100%;
    color: var(--secondary-50);
  
    @include media(desktopHuge) {
      gap: 36px;
      padding: 37px 55px;
  
      &__avatar {
        width: 110px;
        height: 110px;
      }
  
      &__name {
        font-size: 37px;
        line-height: 45px;
      }
  
      &__position {
        font-size: 27px;
        line-height: 33px;
      }
    }
  
    @include media(mobile) {
      gap: 5px;
    }
  }