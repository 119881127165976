@import '../../../../styles/helpers';

.review {
  max-width: 1040px;
  margin: 40px auto 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 40px;
  padding: 64px 0 80px;

  @include media(desktopHuge) {
    max-width: none;
    width: 1760px;
  }

  @include media(desktopBig) {
    max-width: 1280px;
  }

  @include media(landscape) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @include media(portrait) {
    padding-top: 145px;
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 12px;
    padding-bottom: 48px;
  }

  @include media(mobile) {
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 12px;
    padding: 100px 32px 0;
    margin-top: 64px;
  }
}

.content {
  width: 470px;

  @include media(desktopHuge) {
    width: 600px;
  }

  @include media(portrait) {
    padding: 0 64px;
    width: 100%;
  }

  @include media(mobile) {
    width: 100%;
  }
}

.clutchBg {
  position: absolute;
  top: 144px;
  left: 0;
  width: 720px;
  pointer-events: none;

  @include media(desktopHuge) {
    width: 1200px;
    top: 0;
  }

  @include media(landscape) {
    left: 120px;
  }

  @include media(portrait) {
    top: 0;
    width: 767px;
    left: -52px;
  }

  @include media(mobile) {
    top: 0;
    width: 520px;
    left: -80px;
  }

  &.uiux-page-view {
    top: -56px;
    left: -20px;
    z-index: -1;

    @include media(notMobile) {
      top: -206px;
      left: -220px;
    }

    @include media(portrait) {
      top: -102px;
      left: -86px;
    }

    @include media(landscape) {
      left: -124px;
    }

    @include media(desktopAll) {
      top: -206px;
      left: -350px;
    }

    @include media(desktop) {
      left: -240px;
    }

    @include media(desktopSmall) {
       left: -164px;
    }
  }
}

.basedBg {
  display: none;
  position: absolute;
  bottom: -102px;
  left: 75vw;
  z-index: -1;

  @include media(desktopAll) {
    left: 25vw;
  }

  @include media(desktopSmall) {
    left: 60vw;
  }

  @include media(desktop) {
    left: 45vw;
  }

  &.uiux-page-view {

    @include media(desktopAll) {
      display: block;
    }
  }
}

.logo {
  width: 198px;
  height: auto;

  @include media(desktopHuge) {
    width: 320px;
  }

  @include media(portrait) {
    width: 173px;
    margin: 0 auto;
  }

  @include media(mobile) {
    margin: 0 auto;
    width: 172px;
  }
}

.stars {
  margin-top: 20px;
  margin-bottom: 12px;
  gap: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include setProperty(color, var(--primary-500), var(--primary-900));


  @include media(landscape) {
    margin-top: 10px;
  }

  @include media(desktopAll) {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  @include media(portrait) {
    margin-top: 32px;
    justify-content: center;
  }

  @include media(mobile) {
    margin-top: 32px;
    justify-content: center;
  }

  .star {
    width: 13px;
    height: 13px;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    @include media(landscape) {
      width: 16px;
      height: 16px;
    }

    @include media(desktopAll) {
      width: 16px;
      height: 16px;
    }
  }
}

.rating {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  @include media(desktopHuge) {
    font-size: 24px;
    line-height: 32px;
  }

  &__link {
    font-weight: 500;
    text-decoration: underline;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    &:hover {
      text-decoration: none;
    }
  }
}

.text {
  @include setProperty(font-weight, 300, 500);
  font-size: 14px;
  line-height: 2;
  margin-top: 40px;

  @include media(desktopHuge) {
    font-size: 20px;
  }
}

.uiux-page-view {
  flex-direction: column;
  padding: 0 24px;
  margin: 0;
  gap: 24px;
  text-align: start;

  @include media(notMobile) {
    padding: 0;
  }

  .logo {
    margin: 0;
  }

  .content {
    width: auto;
    padding: 0;
  }

  .text {
    font-size: 14px;
    line-height: 2;
    padding: 0;

    margin-top: 32px;

    @include media(desktopAll) {
      margin-top: 40px;
      font-size: 16px;
      letter-spacing: 0.183px;
    }

    &.uiux-page-view {
      margin-top: 24px;

      @include media(portrait) {
        margin-top: 32px;
        padding: 0;
      }

      @include media(landscape) {
        margin-top: 32px;
      }

      @include media(desktopAll) {
        margin-top: 40px;
      }
    }
  }

  .rating {
    &__link {
      font-weight: 300;
    }
  }

  @include media(portrait) {
    padding: 0 64px;
  }

  @include media(landscape) {
    min-width: 300px;
  }

  @include media(desktopAll) {
    min-width: 300px;
  }
}