@import '../../../styles/helpers';

.expertisesWrap {
  width: 100vw;
  position: relative;
  z-index: 2;
}

.expertises {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 120px;

  @include media(mobile) {
    padding: 32px 0 0;
    display: grid;
    gap: 100px;
    justify-content: normal;
  }

  @include media(portrait) {
    padding: 40px 0 0;
  }

  @include media(landscape) {
    padding-right: 120px;
  }

  @include media(desktopAll) {
    padding-right: 140px;
  }

  @include media(desktopSmall) {
    padding-right: 120px;
  }

  @include media(desktop) {
    padding-right: 100px;
  }
}

.servicesTitles {
  position: relative;

  &__title {
    font-weight: 300;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 1px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    margin-bottom: 64px;

    @include media(portrait) {
      font-size: 16px;
      line-height: 1;
    }

    @include media(mobile) {
      font-size: 14px;
      margin-bottom: 40px;
    }
  }

  &__content {
    position: sticky;
    top: 50px;
    left: 0;
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr;

    @include media(mobile) {
      gap: 0;
    }
  }

  &__item {
    font-family: 'KharkivTone', sans-serif;
    font-size: 60px;
    line-height: 1;
    letter-spacing: 0.92px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    padding: 0;
    height: 0;
    transition: height 1s ease, padding 1s ease;
    transition-delay: 0.5s;
    overflow: hidden;

    &.visible {
      padding: 10px 0;
      height: 80px;

      @include media(desktopBig) {
        height: 88px;
      }

      @include media(portrait) {
        height: 68px;
      }

      @include media(mobile) {
        height: 44px;
      }
    }

    &:before {
      content: '.';
    }

    @include media(desktopBig) {
      font-size: 68px;
    }

    @include media(landscape) {
      font-size: 48px;
    }

    @include media(portrait) {
      font-size: 40px;
    }

    @include media(mobile) {
      font-size: 24px;
    }
  }
}

.servicesList {
  width: 50%;
  text-align: right;
  padding: 0 48px;

  @include media(landscape) {
    padding: 0 52px 0 0;
  }

  @include media(portrait) {
    padding: 0;
  }

  @include media(mobile) {
    padding: 0 24px 0 0;
    width: auto;
  }

  &__item {
    @include setProperty(font-weight, 300, 500);
    font-size: 16px;
    line-height: 24px;
    @include setProperty(color, var(--secondary-50), var(--secondary-400));
    padding: 24px 0;

    @include media(desktopBig) {
      font-size: 24px;
      line-height: 38px;
    }

    @include media(landscape) {
      font-size: 14px;
      line-height: 20px;
    }

    @include media(mobile) {
      padding: 12px 0;
      font-size: 14px;
      line-height: 20px;
    }

    a {
      color: inherit;
      transition: all 0.3s ease;

      &:hover {
        @include setProperty(color, var(--primary-500), var(--primary-900));
      }
    }
  }
}

.ctaButton {
  padding-left: 42px;
  padding-right: 42px;
  font-family: 'KharkivTone', sans-serif;
  margin-top: 40px;
  font-size: 16px;

  @include media(tablet) {
    margin-top: 64px;
  }

  @include media(desktopAll) {
    height: 80px;
    font-size: 24px;
    line-height: 32px;
  }

  @include media(desktopSmall) {
    margin-top: 64px;
  }

  @include media(desktop) {
    margin-top: 102px;
  }

  @include media(desktopBig) {
    margin-top: 108px;
  }

  @include media(desktopHuge) {
    margin-top: 124px;
  }
}
