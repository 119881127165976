@import "src/styles/helpers";

.button {
  --button-height: 44px;
  vertical-align: middle;
  padding: 16px 18px 12px;
  font-family: 'KharkivTone', sans-serif;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  @include setProperty(color, var(--dark-bg), var(--light-bg));
  position: relative;
  height: var(--button-height);
  display: flex;
  align-items: center;

  @include media(mobile) {
    margin: 0 auto;
  }

  @include media(tablet) {
    --button-height: 50px;
  }

  @include media(tablet) {
    font-size: 20px;
    display: inline-flex;
  }

  @include media(desktopSmall) {
    font-size: 26px;
    --button-height: 70px;
  }

  @include media(desktop) {
    --button-height: 70px;
    font-size: 26px;
  }

  @include media(desktopBig) {
    --button-height: 72px;
    font-size: 28px;
  }

  @include media(desktopHuge) {
    --button-height: 106px;
    font-size: 40px;
  }

  @include media(desktopAll) {
    display: inline-flex;
  }

  .content {
    white-space: nowrap;
    position: absolute;
    transition: all 0.3s ease;
    //left: 50%;
    //transform: translateX(-50%);
    right: 50%;
    transform: translateX(50%);
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(107, 216, 199, 0.36);
    border-radius: 56px;
    pointer-events: none;
    transition: all 0.3s ease;
    @include setProperty(background, var(--primary-500), var(--primary-900));
  }

  &__icon {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    margin-top: -4px;
    vertical-align: middle;

    @include media(tablet) {
      width: 24px;
      height: 24px;
    }

    @include media(desktopAll) {
      width: 32px;
      height: 32px;
    }

    @include media(desktopHuge) {
      width: 40px;
      height: 40px;
    }
  }

  @include media(notMobile) {
    &:hover {
      @include setProperty(color, var(--light-bg), var(--dark-bg));

      .content {
        padding-right: 12px;
        right: 0;
        transform: translateX(0);

        @include media(tablet) {
          padding-right: 13px;
        }

        @include media(desktopSmall) {
          padding-right: 19px;
        }

        @include media(desktop) {
          padding-right: 19px;
        }

        @include media(desktopBig) {
          padding-right: 20px;
        }

        @include media(desktopHuge) {
          padding-right: 33px;
        }
      }

      .button__icon {
        margin-left: 18px;
        @include setProperty(color, var(--dark-bg), var(--light-bg));

        @include media(tablet) {
          margin-left: 23px;
        }

        @include media(desktopSmall) {
          margin-left: 29px;
        }

        @include media(desktop) {
          margin-left: 29px;
        }

        @include media(desktopBig) {
          margin-left: 30px;
        }

        @include media(desktopHuge) {
          margin-left: 43px;
        }
      }

      &:before {
        width: var(--button-height);
        @include setProperty(background, var(--primary-300), var(--primary-700));
      }
    }

    &:active:before {
      @include setProperty(background, var(--primary-200), var(--primary-500));
    }
  }


}
