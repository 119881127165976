@import "src/styles/helpers";

.stars {
    display: flex;
    gap: 1px;
    align-items: center;
    justify-content: center;

    @include media(landscape) {
        justify-content: flex-start;
    }

    @include media(desktopAll) {
        justify-content: flex-start;
    }

    .star {
        &.filled {
            @include setProperty(color, var(--primary-500), var(--primary-900));
        }
    }
}