@import 'src/styles/helpers';

.hero {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  position: relative;
  background-size: auto 80vh;
  background-repeat: no-repeat;
  background-position: left top;

  @include media(desktopAll) {
    height: 100vh;
  }

  @include media(mobile) {
    justify-content: end;
  }
}

.images {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  transition: top 0.5s ease;

  &_moved {
    top: 621px;

    .ellipse_border,
    .ellipse_gradient {
      opacity: 0;
    }
  }

  @include media(landscape) {
    top: -100px;
    left: -140px;
  }

  @include media(portrait) {}
}

@mixin textSlider() {
  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      @if ($i == 1) {
        transition-delay: 0ms;
      }

      @else {
        transition-delay: #{($i - 1) * 50}ms;
      }
    }
  }
}

@mixin line {
  opacity: 0;
  transition: all .5s ease;
  transform: translateX(200px);
}

.title {
  font-family: 'KharkivTone', 'Adjusted Arial';
  font-size: 56px;
  line-height: 77px;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  text-transform: uppercase;
  text-align: right;
  padding-top: 15%;
  position: relative;

  &:before {
    @include line;
    content: '';
    position: absolute;
    width: 610px;
    height: 1px;
    bottom: -50px;
    right: 30px;
    transition: all .5s ease;
    @include setProperty(background, linear-gradient(90deg, var(--primary-500) 6.23%, transparent 6.23%, transparent 11.48%, var(--dark-line-low-accent) 11.48%), linear-gradient(90deg, var(--primary-900) 6.23%, transparent 6.23%, transparent 11.48%, var(--tertiary-100) 11.48%));

    @include media(mobile) {
      display: none;
    }

    @include media(portrait) {
      display: none;
    }

    @include media(landscape) {
      bottom: 80px;
    }

    @include media(desktopSmall) {
      width: 420px;
    }
  }

  &:after {
    @include line;
    transform: translateX(200px);
    content: '';
    position: absolute;
    width: 116px;
    height: 1px;
    bottom: -50px;
    right: -146px;
    transition: none;
    @include setProperty(background, var(--dark-line-low-accent), var(--tertiary-100));

    @include media(landscape) {
      bottom: 80px;
    }
  }

  @include media(desktopHuge) {
    font-size: 120px;
    line-height: 136px;
    letter-spacing: 2px;
  }

  @include media(desktopBig) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 1px;
  }

  @include media(desktopSmall) {
    font-size: 36px;
    line-height: 44px;
    padding-right: 76px;
    padding-top: 25%;
  }

  @include media(landscape) {
    font-size: 44px;
    line-height: 57px;
    padding: 360px 76px 180px 0;
  }

  @include media(portrait) {
    align-self: center;
    font-size: 36px;
    line-height: 44px;
    padding: 360px 0 66px 0;
  }

  @include media(mobile) {
    text-align: center;
    font-size: 36px;
    line-height: 44px;
    padding: 320px 20px 120px;
    width: 100%;

    br {
      display: none;
    }
  }

  @include media(mobileSmall) {
    padding: 320px 0 120px;
    font-size: 32px;
    line-height: 40px;
  }

  &.visible {

    &:before,
    &:after {
      opacity: 1;
      transform: none;
    }
  }

  &__animation {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 22px;

    @include media(portrait) {
      width: 390px;
      height: 30px;
    }

    @include media(landscape) {
      width: 420px;
      height: 38px;
    }

    @include media(desktopSmall) {
      width: 500px;
      height: 42px;
    }

    @include media(desktop) {
      height: 52px;
      width: 560px;
    }

    @include media(desktopBig) {
      height: 60px;
      width: 860px;
    }

    @include media(desktopHuge) {
      height: 60px;
      width: 860px;
    }
  }

  &__image {
    width: 156px;
    height: 42px;

    @include media(desktopHuge) {
      height: 90px;
      width: auto;
    }

    @include media(desktopSmall) {
      margin-bottom: -3px;
    }

    @include media(landscape) {
      width: 140px;
      height: 38px;
      margin-bottom: -7px;
    }

    @include media(portrait) {
      width: 93px;
      height: 26px;
    }

    @include media(mobile) {
      width: 67px;
      height: 28px;
      margin-bottom: -5px;
      display: none;
    }
  }

  &__industries {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 100%;

    &__container {
      overflow: hidden;
    }

    &__letter {
      display: inline-block;
      transform: translate3d(0, 200px, 0);
      will-change: transform;
      transition: none;
    }

    &_up {

      .title__industries__letter {
        transform: translate3d(0, -200px, 0);
        transition: transform 1.15s cubic-bezier(0.6, 0, 0.2, 1);
        @include textSlider();
      }
    }

    &_middle {

      .title__industries__letter {
        transform: translate3d(0, 0, 0);
        transition: transform 1.15s cubic-bezier(0.6, 0, 0.2, 1);
        @include textSlider();
      }
    }
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.ellipse {
  position: absolute;

  &_draw {
    top: -130px;
    left: -131px;
    transition: top 0.5s ease, left 0.5s ease, width 1s ease, transform 2s ease;

    @include media(portrait) {
      width: 600px;
      height: 600px;
      left: -231px;
      top: -270px;

      &_moved {
        width: 800px;
        height: 800px;
      }
    }

    @include media(mobile) {
      left: -196px;
      top: -180px;
    }

    circle {
      stroke-width: 1px;
    }

    &_moved {
      top: -242px;
      left: -307px;
      transform: rotate(75deg);

      @include media(desktopBig) {
        width: 1600px;
        height: 1600px;
        top: -382px;
        left: -587px;
      }

      @include media(desktop) {
        width: 1600px;
        height: 1600px;
        left: -647px;
        top: -332px;
      }

      @include media(desktopSmall) {
        top: 298px;
        left: -197px;
      }

      @include media(landscape) {
        top: 628px;
        left: -137px;
      }

      @include media(mobile) {
        width: 368px;
        height: 368px;
        top: -22px;
        left: -107px;
      }
    }
  }

  &_border {
    top: 387px;
    left: 345px;
    transition: opacity 1s ease;

    @include media(desktopHuge) {
      left: 640px;
      top: 660px;
    }

    @include media(portrait) {
      left: 215px;
      top: 277px;
    }

    @include media(mobile) {
      left: 195px;
      top: 257px;
    }
  }

  &_gradient {
    left: 367px;
    top: 137px;
    transition: opacity 1s ease;

    @include media(desktopHuge) {
      left: 670px;
      top: 140px;
    }

    @include media(portrait) {
      left: 267px;
      top: 37px;
    }
    @include media(mobile) {
      left: 207px;
      top: 47px;
    }
  }

  &_noise {
    left: 377px;
    top: 259px;
    transition: all 0.5s ease;

    @include media(desktopHuge) {
      left: 777px;
      top: 460px;
    }

    @include media(portrait) {
      left: 257px;
      top: 149px;
    }

    @include media(mobile) {
      left: 230px;
      top: 150px;
    }

    &_moved {
      top: 480px;
      left: 300px;

      @include media(desktopBig) {
        top: 1120px;
        left: 450px;
      }

      @include media(desktop) {
        top: 1090px;
        left: 540px
      }
  
      @include media(desktopSmall) {
        top: 1050px;
        left: 270px;
      }

      @include media(landscape) {
        top: 1380px;
        left: 300px;
      }

      @include media(portrait) {
        top: 489px;
        left: 177px;
      }
      
      @include media(mobile) {
        top: 320px;
        left: 40px;
      }
    }
  }
}

.ctaButton {
  width: 242px;
  margin-bottom: 8px;

  @include media(portrait) {
    width: 300px;
    margin-right: 8px;
  }

  @include media(landscape) {
    width: 322px;
    margin-right: 12px;
  }

  @include media(desktopSmall) {
    width: 392px;
    margin-right: 12px;
  }

  @include media(desktop) {
    width: 392px;
    margin-right: 18px;
  }

  @include media(desktopBig) {
    width: 412px;
    margin-right: 18px;
  }

  @include media(desktopHuge) {
    width: 574px;
    margin-right: 32px;
  }
}